// siteCategories.js

const siteCategories = [
    {
        name: 'Background Check',
        sites: [
            "absolutepeoplesearch.com", "advancedbackgroundchecks.com", "affordablebackgroundchecks.com",
            "backgroundalert.com", "backgroundcheck.run", "backgroundcheckers.net", "backgroundchecks.org",
            "backgroundrecords.com", "checksecrets.com", "cyberbackgroundchecks.com", "fastbackgroundcheck.com",
            "govarrestrecords.org", "idstrong.com", "idtrue.com", "instantcheckmate.com", "intelius.com",
            "kwold.com", "mashpanel.com", "smartbackgroundchecks.com", "spokeo.com", "truthfinder.com",
            "truthrecord.org", "truthviewer.com", "veripages.com"
        ]
    },
    {
        name: 'Public Records',
        sites: [
            "411locate.com", "americaphonebook.com", "arivify.com", "courtcasefinder.com",
            "criminalpages.com", "criminalregistry.org", "dataveria.com", "govwarrantsearch.org",
            "hpcc-usa.org", "northcarolinaresidentdatabase.com", "ohioresidentdatabase.com",
            "publicdatacheck.com", "publicdatadigger.com", "publicdatausa.com", "publicinfoservices.com",
            "publicrecordcenter.com", "publicrecords.com", "publicrecords.searchsystems.net",
            "publicrecords.site", "publicrecordscenter.org", "publicrecordsofficial.com",
            "publicrecordsreviews.com", "publicsrecords.com", "staterecords.org", "sheriffsdepartment.net",
            "texasarrests.org", "texasarrestwarrants.org", "texaswarrantroundup.org", "usrecords.net",
            "uswarrants.org", "verifyarrestwarrant.com"
        ]
    },
    {
        name: 'People Search',
        sites: [
            "24counter.com", "411.com", "411.info", "411locate.com", "addressbook.com", "addresses.com",
            "advanced-people-search.com", "allpeople.com", "anywho.com", "clustrmaps.com", "cubib.com",
            "dataveria.com", "findpeoplesearch.com", "findrec.com", "gladiknow.com", "golookup.com",
            "mylife.com", "neighborwho.com", "newenglandfacts.com", "nuwber.com", "onlinesearches.com",
            "people-background-check.com", "people-searchers.com", "people-wizard.com", "people.yellowpages.com",
            "peoplebyname.com", "peoplebyphone.com", "peoplefinders.com", "peoplelooker.com", "peoplesearch.com",
            "peoplesearch123.com", "peoplesearcher.com", "peoplesearchexpert.com", "peoplesearchnow.com",
            "peoplesearchusa.org", "peoplesmart.com", "peoplewhiz.com", "personsearchers.com",
            "persontrust.com", "peoplewhizr.com", "profile.systems", "quickpeopletrace.com",
            "radaris.com", "revealname.com", "searchpeoplefree.com", "searchpublicrecords.com",
            "searchquarry.com", "truepeoplesearch.com", "usapeopledata.com", "usatrace.com",
            "usfriendsreunited.com", "zabasearch.com"
        ]
    },
    {
        name: 'Social Media',
        sites: [
            "allareacodes.com", "bumper.com", "clubset.com", "corporationwiki.com", "curadvisor.com",
            "knowthycustomer.com", "neighbor.report", "officialusa.com", "peekyou.com", "socialcatfish.com",
            "spyfly.com"
        ]
    },
    {
        name: 'Contact Info',
        sites: [
            "callercenter.com", "cellrevealer.com", "confidentialphonelookup.com", "contacttrace.com",
            "inforver.com", "infotracer.com", "inmatessearcher.com", "numberguru.com", "numberville.com",
            "oldphonebook.com", "phonebooks.com", "plcom.net", "privatenumberchecker.com",
            "privateeye.com", "reversephonecheck.com", "reversephonelookup.com", "telephonedirectories.us",
            "thisnumber.com", "wellnut.com"
        ]
    },
    {
        name: 'Address History',
        sites: [
            "addressbook.com", "addresses.com", "addresssearch.com", "floridaresidentsdirectory.com",
            "homeentry.com", "homemetry.com", "neighborwho.com", "newenglandfacts.com",
            "northcarolinaresidentdatabase.com", "ohioresidentdatabase.com", "rehold.com",
            "residentdirectory.com"
        ]
    }
];

export { siteCategories };
