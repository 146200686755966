import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/authService';
import { socket } from '../socket';
import LoadingSpinner from '../components/LoadingSpinner';
import UserInfo from '../components/UserInfo';
import DashboardStats from '../components/DashboardStats';
import FeatureToggles from '../components/FeatureToggles';
import DataBrokerListComponent from '../components/DataBrokerListComponent';
import DataPointsComponent from '../components/DataPointsComponent';
import ErrorFallback from '../components/ErrorFallback';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavBar from '../components/MobileNavbar';
import { Footer } from "../components/footer";
import { formatDataBrokerList } from '../utils';
import './Dashboard.css';
import ImagePreview from '../components/ImagePreview';
import { getScreenshotUrl } from '../utils';

// Mock data (Optional: Consider removing if API provides complete data)
const mockDashboardData = {
    user: {
        email: "demo@example.com",
        firstName: "John",
        lastName: "Doe",
        memberSince: new Date().toISOString(),
        subscriptionStatus: 'Trial'
    },
    stats: {
        dataBrokerExposure: 75,
        sitesScanned: 0,
        profilesFound: 0,
        totalMatches: 0,
        progress: 0,
        isScanning: false,
        lastScanTime: null,
        nextScanTime: new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)).toISOString(),
        currentSite: null
    },
    dataBrokerList: []
};

// Initial state to prevent null reference errors
const initialDashboardState = {
    user: {
        email: "",
        firstName: "",
        lastName: "",
        memberSince: new Date().toISOString(),
        subscriptionStatus: 'Getting started...'
    },
    stats: {
        dataBrokerExposure: 0,
        sitesScanned: 0,
        profilesFound: 0,
        totalMatches: 0,
        progress: 0,
        isScanning: false,
        lastScanTime: 'Starting soon',
        nextScanTime: 'Scheduled for tomorrow',
        currentSite: 'Preparing your first scan'
    },
    featureToggles: {
        multi_factor_auth: false,
        role_based_access: false,
        monitoring_verification: false,
        data_leak_notification: false
    }
};

const initialDataBrokers = [
    { name: "Preparing scan...", status: "Getting ready" },
    { name: "Scanning data brokers...", status: "Queued" },
    { name: "Checking exposures...", status: "Queued" },
    { name: "Verifying data points...", status: "Queued" }
];

const getThreatLevel = (pwnCount) => {
    if (pwnCount > 10000000) return 'critical';
    if (pwnCount > 1000000) return 'high';
    if (pwnCount > 100000) return 'medium';
    return 'low';
};

const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num;
};

const stages = [
    "Initializing...",
    "Validating input...",
    "Connecting to servers...",
    "Fetching data...",
    "Processing results...",
    "Generating comprehensive report..."
];

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Dashboard = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [dashboardData, setDashboardData] = useState(initialDashboardState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getToken, user, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [dataBrokerList, setDataBrokerList] = useState(initialDataBrokers);
    const [dataBrokerLoading, setDataBrokerLoading] = useState(true);
    const [isNewUser, setIsNewUser] = useState(false);
    const [breaches, setBreaches] = useState([]);
    const [breachLoading, setBreachLoading] = useState(false);
    const [showMoreBreaches, setShowMoreBreaches] = useState(false);
    const [breachError, setBreachError] = useState(null);
    const [showBreachAnalysis, setShowBreachAnalysis] = useState(false);
    const [breachAnalysisStage, setBreachAnalysisStage] = useState(0);
    const [featureToggles, setFeatureToggles] = useState({
        data_leak_notification: false,
        monitoring_verification: false,
        multi_factor_auth: false,
        role_based_access: false
    });

    const isDevelopment = process.env.NODE_ENV === 'development';

    // Initialize Socket.IO connection (handled in socket.js)
    useEffect(() => {
        if (isAuthenticated && !isDevelopment) {
            if (socket && socket.connected) {
                if (user?.email) {
                    socket.emit('register', user.email);
                }
            }
        }

        // Handle socket events
        if (socket) {
            socket.on('connect', () => {
                console.log('[Socket] Connected successfully');
                if (user?.email) {
                    socket.emit('register', user.email);
                }
            });

            socket.on('stats_update', (data) => {
                setDashboardData(prev => ({
                    ...prev,
                    stats: {
                        ...(prev.stats || {}),
                        ...data
                    }
                }));
            });

            socket.on('dashboard_update', (data) => {
                setDashboardData(prev => ({
                    ...prev,
                    ...data
                }));
            });

            return () => {
                socket.off('connect');
                socket.off('stats_update');
                socket.off('dashboard_update');
                // Do not disconnect socket here to allow reuse
            };
        }
    }, [isAuthenticated, user, isDevelopment, socket]);

    useEffect(() => {
        fetchData();
    }, [user]);

    useEffect(() => {
        const checkBreaches = async () => {
            if (!user?.email || !dashboardData?.featureToggles?.data_leak_notification) return;
            
            setBreachLoading(true);
            setBreachError(null);

            try {
                const response = await fetch('https://dashboard-api-server.fly.dev/api/check-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: user.email }),
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        setBreaches([]);
                    } else if (response.status === 401) {
                        throw new Error('Unauthorized: Please check your API key.');
                    } else if (response.status === 429) {
                        throw new Error('Rate limit exceeded. Please try again later.');
                    } else {
                        const errorData = await response.json();
                        throw new Error(errorData.message || 'An error occurred while checking the email.');
                    }
                } else {
                    const data = await response.json();
                    console.log('API response data:', data);
                    setBreaches(data);
                }
            } catch (error) {
                console.error('Error fetching breaches:', error);
                setBreachError(error.message || 'Failed to fetch breach data');
            } finally {
                setBreachLoading(false);
            }
        };

        checkBreaches();
    }, [user?.email, dashboardData?.featureToggles?.data_leak_notification]);

    const fetchData = async () => {
        try {
            setLoading(true);
            
            if (isDevelopment) {
                console.log('[Dashboard] Using local API endpoint');
                try {
                    const token = localStorage.getItem('token');
                    const response = await fetch('http://localhost:5001/api/dashboard', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        credentials: 'include'
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    console.log('[Dashboard] Received data:', data);
                    
                    setDashboardData(data);
                    setDataBrokerList(formatDataBrokerList(
                        data.dataBrokerList || [
                            "DataBroker.com",
                            "InfoFinder.net",
                            "PersonSearch.io",
                            "DataVault.com"
                        ], 
                        data.stats?.currentSite
                    ));
                    setError(null);
                } catch (apiError) {
                    console.error('[Dashboard] Local API error, falling back to mock data:', apiError);
                    // Fallback to mock data if API fails
                    const mockData = {
                        ...mockDashboardData,
                        user: {
                            ...mockDashboardData.user,
                            email: user?.email || mockDashboardData.user.email,
                            firstName: user?.firstName || mockDashboardData.user.firstName,
                            lastName: user?.lastName || mockDashboardData.user.lastName
                        }
                    };
                    
                    setDashboardData(mockData);
                    setDataBrokerList(formatDataBrokerList([
                        "DataBroker.com",
                        "InfoFinder.net",
                        "PersonSearch.io",
                        "DataVault.com"
                    ], mockData.stats.currentSite));
                }
                return; // Exit after development mode handling
            }

            // Production code
            const token = localStorage.getItem('token');
            
            if (!token) {
                console.log('[Dashboard] No token found, redirecting to login');
                navigate('/login');
                return;
            }

            // Make API call using the `api` Axios instance
            const response = await api.get('/dashboard');
            setDashboardData(response.data);
            setDataBrokerList(formatDataBrokerList(response.data.brokers, response.data.stats.currentSite));
            setError(null);

        } catch (error) {
            console.error('[Dashboard] Error fetching data:', error);
            setError('Failed to load dashboard data.');
        } finally {
            setLoading(false);
            setDataBrokerLoading(false);
        }
    };

    // Handle window resize
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Handle Start Scan
    const handleStartScan = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token && !isDevelopment) {
                navigate('/login');
                return;
            }

            setIsNewUser(false);

            if (!isDevelopment) {
                // Call the scan endpoint using the `api` instance
                const response = await api.post('/dashboard/scan', {}, {
                    headers: {
                        'Content-Type': 'application/json'
                        // 'Authorization' header is already handled by the interceptor in authService.js
                    }
                });

                if (response.status === 200) {
                    // Emit socket event after successful API call
                    if (socket) {
                        socket.emit('start_scan', {
                            userEmail: user.email
                        });
                    }
                } else {
                    throw new Error('Failed to start scan');
                }
            } else {
                // Simulate scan initiation in development
                console.log('[Dashboard] Scan initiated in development mode (mock)');
                setDashboardData(prev => ({
                    ...prev,
                    stats: {
                        ...prev.stats,
                        isScanning: true,
                        currentSite: "DataBroker.com"
                    }
                }));
                setDataBrokerList(formatDataBrokerList([
                    "DataBroker.com",
                    "InfoFinder.net",
                    "PersonSearch.io",
                    "DataVault.com"
                ], "DataBroker.com"));
            }
        } catch (error) {
            console.error('Error starting scan:', error);
            setError('Failed to start scan. Please try again.');
        }
    };

    const liveSearchItems = [
        { 
            url: `https://www.fastpeoplesearch.com/name/${user?.firstName}-${user?.lastName}`, 
            siteName: 'FastPeopleSearch' 
        },
        { 
            url: `https://www.truepeoplesearch.com/results?name=${user?.firstName}%20${user?.lastName}`, 
            siteName: 'TruePeopleSearch' 
        }
    ];

    const handleImageClick = (url) => {
        window.open(url, '_blank');
    };

    // Add this function to handle toggle changes
    const handleFeatureToggle = (feature) => {
        setFeatureToggles(prev => ({
            ...prev,
            [feature]: !prev[feature]
        }));
    };

    // Conditional returns after all hooks
    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <ErrorFallback error={error} onRetry={fetchData} />;
    }

    return (
        <>
            {isMobile ? <MobileNavBar /> : <Navbar />}
            <div className="dashboard-page">
                <Sidebar />
                <div className="content-wrapper">
                    <div className="dashboard-main">
                        <div className="dashboard-content">
                            <DashboardHeader 
                                currentTask={dashboardData?.stats?.currentSite}
                                dataBrokers={dataBrokerList}
                                isScanning={dashboardData?.stats?.isScanning}
                            />
                            
                            <div className="dashboard-grid">
                                <div className="stats-section">
                                    <DashboardStats stats={dashboardData?.stats} />
                                </div>
                                
                                <div className="data-broker-section">
                                    <DataBrokerListComponent 
                                        dataBrokers={dataBrokerList}
                                        isScanning={dashboardData?.stats?.isScanning}
                                        currentSite={dashboardData?.stats?.currentSite}
                                        profilesFound={dashboardData?.stats?.profilesFound}
                                    />
                                </div>
                                
                                <div className="user-features-section">
                                    <ErrorBoundary>
                                        <UserInfo user={dashboardData?.user} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <FeatureToggles 
                                            isDevelopment={isDevelopment}
                                            subscriptionStatus={dashboardData?.user?.subscriptionStatus}
                                            featureToggles={featureToggles}
                                            onToggle={handleFeatureToggle}
                                        />
                                    </ErrorBoundary>
                                </div>

                                <div className="live-search-section">
                                    <h3>Live Search</h3>
                                    <div className="live-search-images">
                                        {liveSearchItems.map(item => (
                                            <ImagePreview 
                                                key={item.url}
                                                item={item}
                                                onImageClick={handleImageClick}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {dashboardData?.featureToggles?.data_leak_notification && (
                                    <div className="breach-check-section">
                                        <h3>Data Breach Monitor</h3>
                                        {breachError ? (
                                            <div className="error-message">{breachError}</div>
                                        ) : breaches.length > 0 ? (
                                            <div className="breach-results">
                                                <div className="breach-list">
                                                    {breaches.slice(0, showMoreBreaches ? undefined : 4).map((breach, index) => (
                                                        <div key={index} className="breach-item">
                                                            <div className="breach-header">
                                                                <h3>{breach.Name}</h3>
                                                                <span className={`threat-level ${getThreatLevel(breach.PwnCount)}`}>
                                                                    {getThreatLevel(breach.PwnCount)}
                                                                </span>
                                                            </div>
                                                            <div className="breach-details">
                                                                <p className="breach-date">
                                                                    Breach date: {new Date(breach.BreachDate).toLocaleDateString()}
                                                                </p>
                                                                <p className="pwn-count">
                                                                    {formatNumber(breach.PwnCount)} accounts affected
                                                                </p>
                                                                <div className="compromised-data">
                                                                    <h4>Compromised Data:</h4>
                                                                    <ul>
                                                                        {breach.DataClasses.slice(0, 3).map((dataClass, i) => (
                                                                            <li key={i}>{dataClass}</li>
                                                                        ))}
                                                                        {breach.DataClasses.length > 3 && (
                                                                            <li>+{breach.DataClasses.length - 3} more...</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {breaches.length > 4 && (
                                                    <button
                                                        className="show-more-button"
                                                        onClick={() => setShowMoreBreaches(!showMoreBreaches)}
                                                    >
                                                        <span>
                                                            {showMoreBreaches 
                                                                ? "Show Less" 
                                                                : `Show ${breaches.length - 4} More Breaches`}
                                                        </span>
                                                        <div className="button-icon">
                                                            {showMoreBreaches ? "↑" : "↓"}
                                                        </div>
                                                    </button>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="scanning-message">
                                                <div className="pulse-dot"></div>
                                                <p>Actively monitoring for data breaches...</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Dashboard;
