// src/ScrollToTop.jsx

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();
    const previousPath = useRef(pathname);
    const timeoutRef = useRef(null);

    useEffect(() => {
        // Clear any existing timeout to prevent multiple scrolls
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (previousPath.current !== pathname) {
            timeoutRef.current = setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth', // Change to 'auto' if you prefer instant scrolling
                });
                previousPath.current = pathname;
                console.log('ScrollToTop executed for path:', pathname);
            }, 100); // Debounce duration in milliseconds
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [pathname]);

    return null;
};

export default ScrollToTop;
