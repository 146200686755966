export const fetchMatchProbability = async (firstName, lastName, city, country) => {
    const apiKey = 'sk-proj-gvDD3...'; // Your API key
    const location = `${city}, ${country}`.trim();

    const queryGPT = async (content) => {
        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "system",
                            content: `You are an expert at analyzing personal data exposure risk. 
                            Consider factors like:
                            - Name commonality in the given location
                            - Location's data privacy regulations
                            - Digital footprint likelihood based on demographics
                            Return only a number from 0-5 representing risk level.`
                        },
                        {
                            role: "user",
                            content
                        }
                    ],
                    max_tokens: 10,
                })
            });

            if (!response.ok) throw new Error(`Error: ${response.statusText}`);
            
            const data = await response.json();
            let riskScore = parseFloat(data.choices[0].message.content.trim());
            return Math.round(Math.max(0, Math.min(5, riskScore))); // Ensure score is 0-5
        } catch (error) {
            console.error('Error fetching risk score:', error);
            return 3; // Default to medium risk on error
        }
    };

    try {
        // Get risk score based on name and location
        const query = `Analyze the digital exposure risk for "${firstName} ${lastName}" in "${location}". Consider name uniqueness, location privacy laws, and likely digital presence. Score 0-5.`;
        const riskScore = await queryGPT(query);

        // Calculate matches based on risk score
        const baseMatches = {
            5: { min: 35, max: 45 },  // High risk
            4: { min: 25, max: 35 },  // Moderate-high risk
            3: { min: 15, max: 25 },  // Moderate risk
            2: { min: 8, max: 15 },   // Low-moderate risk
            1: { min: 3, max: 8 },    // Low risk
            0: { min: 0, max: 3 }     // Minimal risk
        };

        // Get random number within range for more realistic variation
        const getRandomInRange = (min, max) => 
            Math.floor(Math.random() * (max - min + 1)) + min;

        // Calculate total matches
        const { min, max } = baseMatches[riskScore];
        const totalMatches = getRandomInRange(min, max);

        // Calculate threat percentage (matches / total sites)
        const totalSites = 208; // Total number of sites we're "scanning"
        const threatPercentage = totalMatches / totalSites;

        console.log({
            name: `${firstName} ${lastName}`,
            location,
            riskScore,
            totalMatches,
            threatPercentage: (threatPercentage * 100).toFixed(2) + '%'
        });

        return threatPercentage;
    } catch (error) {
        console.error('Error calculating match probability:', error);
        return 0.1; // Default to 10% on error
    }
};
