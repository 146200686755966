// src/pages/DataLeakPage.js

import { useState, useEffect } from "react";
import "./DataLeakPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { Footer } from "../components/footer"; // Ensure correct import
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Spinner from "../components/Spinner"; // Optional: For loading indicator

const DataLeakPage = () => {
    // Email Leak Checker States
    const [email, setEmail] = useState("");
    const [emailLoading, setEmailLoading] = useState(false);
    const [emailBreaches, setEmailBreaches] = useState([]);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [showEmailAnalysis, setShowEmailAnalysis] = useState(false);
    const [emailAnalysisStage, setEmailAnalysisStage] = useState(0);
    const [showMore, setShowMore] = useState(false);

    // Data Brokers Check States
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [brokerLoading, setBrokerLoading] = useState(false);
    const [brokerErrorMessage, setBrokerErrorMessage] = useState("");
    const [showBrokerAnalysis, setShowBrokerAnalysis] = useState(false);
    const [brokerAnalysisStage, setBrokerAnalysisStage] = useState(0);
    const [brokerInfoResult, setBrokerInfoResult] = useState(null);

    // Add this state at the top with other states
    const [showDataBrokersSection, setShowDataBrokersSection] = useState(false);

    const stages = [
        "Initializing...",
        "Validating input...",
        "Connecting to servers...",
        "Fetching data...",
        "Processing results...",
        "Generating comprehensive report..."
    ];

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Handler for Email Leak Checker
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setEmailErrorMessage("Please enter a valid email address.");
            return;
        }

        console.log("Email Submit Triggered");
        setEmailLoading(true);
        setEmailErrorMessage("");
        setShowEmailAnalysis(true);
        setEmailAnalysisStage(0);
        setEmailBreaches([]);
        setShowMore(false);

        try {
            // Simulate analysis stages
            for (let stage = 1; stage <= stages.length; stage++) {
                setEmailAnalysisStage(stage);
                await delay(500); // Simulate time taken for each stage
            }
//**dont remove anything about this */
            const response = await fetch('https://dashboard-api-server.fly.dev/api/check-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                if (response.status === 404) {
                    setEmailBreaches([]);
                } else if (response.status === 401) {
                    setEmailErrorMessage('Unauthorized: Please check your API key.');
                } else if (response.status === 429) {
                    setEmailErrorMessage('Rate limit exceeded. Please try again later.');
                } else {
                    const errorData = await response.json();
                    setEmailErrorMessage(errorData.message || 'An error occurred while checking the email.');
                }
            } else {
                const data = await response.json();
                console.log('API response data:', data);
                setEmailBreaches(data);
                // Only show data brokers section after results are displayed
                setTimeout(() => {
                    setShowDataBrokersSection(true);
                }, 1000); // Delay to show after breach results are visible
            }
        } catch (error) {
            console.error('Error fetching email breaches:', error);
            setEmailErrorMessage('Failed to fetch email breaches. Please try again later.');
        } finally {
            setEmailLoading(false);
        }
    };

    // Handler for Data Brokers Check (Assuming similar proxy setup)
    const handleBrokerSubmit = async (e) => {
        e.preventDefault();
        if (!firstName || !lastName) {
            setBrokerErrorMessage("Please enter both first and last names.");
            return;
        }

        console.log("Broker Submit Triggered");
        setBrokerLoading(true);
        setBrokerErrorMessage("");
        setShowBrokerAnalysis(true);
        setBrokerAnalysisStage(0);
        setBrokerInfoResult(null);

        try {
            // Simulate analysis stages
            for (let stage = 1; stage <= stages.length; stage++) {
                setBrokerAnalysisStage(stage);
                await delay(500); // Simulate time taken for each stage
            }

            // Construct the cleandata.me URL with the user's input
            const cleanDataUrl = `https://app.cleandata.me/location?first=${encodeURIComponent(firstName)}&last=${encodeURIComponent(lastName)}`;
            
            // Redirect to cleandata.me
            window.location.href = cleanDataUrl;

        } catch (error) {
            console.error('Error in broker check:', error);
            setBrokerErrorMessage('Failed to process your request. Please try again later.');
        } finally {
            setBrokerLoading(false);
        }
    };

    // Add these helper functions inside your DataLeakPage component
    const getThreatLevel = (pwnCount) => {
        if (pwnCount > 10000000) return 'critical';
        if (pwnCount > 1000000) return 'high';
        if (pwnCount > 100000) return 'medium';
        return 'low';
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        return num;
    };

    return (
        <div className="data-leak-page">
            {/* Navbar */}
            <Navbar />
            <MobileNavbar />

            {/* Content Wrapper */}
            <div className="content-wrapper">
                {/* Header Section */}
                <div className="data-leak-header">
                    <h1>Check Your Data Breaches</h1>
                    <p>Enter your email address to see if it has been involved in any data breaches.</p>
                </div>

                {/* Eraser Icon */}
                <div className="eraser-icon-container">
                    <FontAwesomeIcon icon={faEraser} size="2x" className="eraser-icon" />
                </div>

                {/* Email Form */}
                <div className="data-leak-form card">
                    <h2>Check Your Email for Data Breaches</h2>
                    <form onSubmit={handleEmailSubmit}>
                        <div className="input-group">
                            <input
                                type="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="email-input"
                                aria-label="Email Address"
                            />
                            <button
                                type="submit"
                                className="check-button"
                                disabled={emailLoading}
                                aria-label="Check Email for Data Leaks"
                            >
                                {emailLoading ? (
                                    <>
                                        <div className="spinner" />
                                        <span>Checking...</span>
                                    </>
                                ) : (
                                    "Check Now"
                                )}
                            </button>
                        </div>
                    </form>
                    {/* Error Message */}
                    {emailErrorMessage && <p className="error-message">{emailErrorMessage}</p>}
                    {/* Analysis Stages */}
                    {showEmailAnalysis && (
                        <div className="loading-stages">
                            {stages.map((stageText, index) => (
                                <div key={index} className={`stage ${emailAnalysisStage > index ? 'active' : ''}`}>
                                    <div className="stage-indicator"></div>
                                    <div className="stage-text">{stageText}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Breach Results */}
                    {emailBreaches.length > 0 && (
                        <div className="breach-results card">
                            <h2>Breaches Found:</h2>
                            <div className="breach-list">
                                {emailBreaches.slice(0, 4).map((breach, index) => (
                                    <div key={index} className="breach-item">
                                        <div className="breach-header">
                                            <h3>{breach.Name}</h3>
                                            <span className={`threat-level ${getThreatLevel(breach.PwnCount)}`}>
                                                {getThreatLevel(breach.PwnCount)}
                                            </span>
                                        </div>
                                        <div className="breach-details">
                                            <p className="breach-date">Breach date: {new Date(breach.BreachDate).toLocaleDateString()}</p>
                                            <p className="pwn-count">{formatNumber(breach.PwnCount)} accounts affected</p>
                                            <div className="compromised-data">
                                                <h4>Compromised Data:</h4>
                                                <ul>
                                                    {breach.DataClasses.slice(0, 3).map((dataClass, i) => (
                                                        <li key={i}>{dataClass}</li>
                                                    ))}
                                                    {breach.DataClasses.length > 3 && (
                                                        <li>+{breach.DataClasses.length - 3} more...</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {emailBreaches.length > 4 && (
                                <button
                                    className="show-more-button"
                                    onClick={() => setShowMore(!showMore)}
                                    aria-label="Show More Breaches"
                                >
                                    <span>{showMore ? "Show Less" : `Show ${emailBreaches.length - 4} More Breaches`}</span>
                                    <div className="button-icon">
                                        {showMore ? "↑" : "↓"}
                                    </div>
                                </button>
                            )}
                            {showMore && (
                                <div className="breach-list">
                                    {emailBreaches.slice(4).map((breach, index) => (
                                        <div key={index + 4} className="breach-item">
                                            <div className="breach-header">
                                                <h3>{breach.Name}</h3>
                                                <span className={`threat-level ${getThreatLevel(breach.PwnCount)}`}>
                                                    {getThreatLevel(breach.PwnCount)}
                                                </span>
                                            </div>
                                            <div className="breach-details">
                                                <p className="breach-date">Breach date: {new Date(breach.BreachDate).toLocaleDateString()}</p>
                                                <p className="pwn-count">{formatNumber(breach.PwnCount)} accounts affected</p>
                                                <div className="compromised-data">
                                                    <h4>Compromised Data:</h4>
                                                    <ul>
                                                        {breach.DataClasses.slice(0, 3).map((dataClass, i) => (
                                                            <li key={i}>{dataClass}</li>
                                                        ))}
                                                        {breach.DataClasses.length > 3 && (
                                                            <li>+{breach.DataClasses.length - 3} more...</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Breach Statistics */}
                <div className="breach-stats">
                    <div className="breach-stat">
                        <h2>821</h2>
                        <p>Known Breaches</p>
                        <div className="stat-icon">🔒</div>
                    </div>
                    <div className="breach-stat">
                        <h2>115,796</h2>
                        <p>Data Pastes</p>
                        <div className="stat-icon">📄</div>
                    </div>
                    <div className="breach-stat">
                        <h2>228M</h2>
                        <p>Exposed Accounts</p>
                        <div className="stat-icon">👤</div>
                    </div>
                </div>

                {/* Privacy Statement */}
                <p className="privacy-statement card">
                    Your privacy is our priority. All entries are completely secure, never stored, and never shared. Guaranteed.
                </p>

                {/* Featured In Section */}
                <div className="featured-in-section card">
                    <h3>Featured In:</h3>
                    <p>
                        Our tool has been highlighted in renowned publications such as <strong>The New York Times</strong> and <strong>NerdWallet</strong>.
                    </p>
                </div>

                {/* Data Brokers Check Section - Only show after email check */}
                {showDataBrokersSection && emailBreaches.length > 0 && (
                    <div className="data-brokers-check card fade-in">
                        <div className="section-divider">
                            <span>Additional Protection</span>
                        </div>
                        <h2>Check If Your Information Is Sold by Data Brokers</h2>
                        <p>Enter your first and last name below to check if your data is being sold by data brokers:</p>
                        <form onSubmit={handleBrokerSubmit}>
                            <div className="name-inputs">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    className="name-input"
                                    aria-label="First Name"
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    className="name-input"
                                    aria-label="Last Name"
                                />
                            </div>
                            <button
                                type="submit"
                                className="check-info-button"
                                disabled={brokerLoading}
                                aria-label="Check Information with Data Brokers"
                            >
                                {brokerLoading ? "Checking..." : "Check Brokers"}
                            </button>
                        </form>

                        {/* Error Message for Data Brokers Check */}
                        {brokerErrorMessage && <p className="error-message">{brokerErrorMessage}</p>}

                        {/* Analysis Stages for Data Brokers Check */}
                        {showBrokerAnalysis && (
                            <div className="loading-stages">
                                {stages.map((stageText, index) => (
                                    <div key={index} className={`stage ${brokerAnalysisStage > index ? 'active' : ''}`}>
                                        <div className="stage-indicator"></div>
                                        <div className="stage-text">{stageText}</div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Broker Info Result */}
                        {brokerAnalysisStage === stages.length && brokerInfoResult && (
                            <div className="broker-info-result card">
                                <h2>Broker Information:</h2>
                                <p>{brokerInfoResult}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default DataLeakPage;
