// src/components/Sidebar.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faUserEdit,
    faExchangeAlt,
    faListAlt,
    faLifeRing,
    faPlus,
    faChartLine,
    faPulse
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import profileImage from '../assets/profile.jpg';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [userName, setUserName] = useState(() => {
        // Initialize from localStorage if available
        const savedUser = localStorage.getItem('userData');
        return savedUser ? JSON.parse(savedUser) : { firstName: '', lastName: '' };
    });
    const [isLoading, setIsLoading] = useState(!localStorage.getItem('userData'));
    const location = useLocation();

    useEffect(() => {
        const fetchUserName = async () => {
            const token = localStorage.getItem('token');
            const savedUser = localStorage.getItem('userData');
            
            if (savedUser) {
                setUserName(JSON.parse(savedUser));
                setIsLoading(false);
                return;
            }

            if (!token) {
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch('https://cleandata.fly.dev/api/user-data', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (!response.ok) throw new Error('Failed to fetch user data');

                const user = await response.json();
                const userData = {
                    firstName: user.first_name || '',
                    lastName: user.last_name || ''
                };
                
                localStorage.setItem('userData', JSON.stringify(userData));
                setUserName(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserName();
    }, []);

    return (
        <div className="sidebar">
            <div className="sidebar-profile">
                <div className="profile-pic-wrapper">
                    <img src={profileImage} alt="User" className="profile-pic" />
                    <div className="online-status">
                        <div className="status-dot"></div>
                    </div>
                </div>
                <div className="profile-info">
                    {isLoading ? (
                        <div className="profile-skeleton">
                            <div className="skeleton-text"></div>
                            <div className="skeleton-text small"></div>
                        </div>
                    ) : (
                        <>
                            <h3 className="user-name">
                                {userName.firstName && userName.lastName ? 
                                    `${userName.firstName} ${userName.lastName}` : 
                                    'Welcome Back!'}
                            </h3>
                            <div className="status-badge">
                                <span className="status-icon"></span>
                                Protected
                            </div>
                        </>
                    )}
                </div>
            </div>
            <nav className="sidebar-nav">
                <Link to="/dashboard" className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" />
                    <span>Dashboard</span>
                </Link>
                <Link to="/data-removals" className={`nav-item ${location.pathname === '/data-removals' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faChartLine} className="nav-icon" />
                    <span>Live Reports</span>
                    <div className="status-indicator">
                        <span className="status-dot"></span>
                        Active
                    </div>
                </Link>
                <Link to="/edit-info" className={`nav-item ${location.pathname === '/edit-info' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faUserEdit} className="nav-icon" />
                    <span>Edit Your Info</span>
                </Link>
                <Link to="/change-plan" className={`nav-item ${location.pathname === '/change-plan' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faExchangeAlt} className="nav-icon" />
                    <span>Change Plan</span>
                </Link>
                <Link to="/sites" className={`nav-item ${location.pathname === '/sites' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faListAlt} className="nav-icon" />
                    <span>Sites We Cover</span>
                </Link>
                <Link to="/support" className={`nav-item ${location.pathname === '/support' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faLifeRing} className="nav-icon" />
                    <span>Support</span>
                </Link>
            </nav>
            <button className="add-family-member-button">
                <FontAwesomeIcon icon={faPlus} className="button-icon" />
                <span>Add Family Member</span>
            </button>
        </div>
    );
};

export default Sidebar;
