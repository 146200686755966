import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEraser, FaLock, FaShieldVirus, FaSearchLocation, FaEnvelope, FaSignOutAlt, FaRobot } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import './Navbar.css';
import GalaxyButton from './GalaxyButton';
import { useAuth } from '../context/AuthContext';
import useWindowSize from '../hooks/useWindowSize';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const { user, getToken, logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
            window.location.href = 'https://cleandata.me';
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        if (isOpen) setDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const particlesInit = async (main) => {
        await loadFull(main);
    };

    const particlesOptions = {
        background: { color: { value: "transparent" } },
        fpsLimit: 60,
        interactivity: {
            events: {
                onHover: { enable: true, mode: "repulse" },
                onClick: { enable: true, mode: "push" },
                resize: true,
            },
            modes: {
                push: { quantity: 4 },
                repulse: { distance: 100, duration: 0.4 },
            },
        },
        particles: {
            color: { value: ["#ffffff", "#D8FF60", "#99f2c8"] },
            links: { 
                color: "#ffffff", 
                distance: 150, 
                enable: true, 
                opacity: 0.2, 
                width: 1 
            },
            collisions: { enable: false },
            move: { 
                direction: "none", 
                enable: true, 
                outModes: { default: "bounce" }, 
                speed: 1 
            },
            number: { 
                density: { enable: true, area: 800 }, 
                value: 30 
            },
            opacity: { value: 0.2 },
            shape: { type: "circle" },
            size: { value: { min: 1, max: 2 } },
        },
        detectRetina: true,
    };

    // Close dropdown when navigating
    const handleNavigation = (to) => {
        setDropdownOpen(false);
        setIsOpen(false);
        navigate(to);
    };

    if (width <= 768) {
        return null;
    }

    return (
        <header className="navbar">
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={particlesOptions}
                className="particles-background"
            />
            <div className="navbar-content">
                <div className="logo-container">
                    <FaEraser className="eraser-icon" />
                    <span className="logo-text">CleanData</span>
                    <span className="logo-subtitle">.me</span>
                </div>
                <button
                    className="menu-toggle"
                    onClick={toggleMenu}
                    aria-label="Toggle navigation menu"
                    aria-expanded={isOpen}
                    aria-controls="primary-navigation"
                >
                    <span className="menu-icon">&#9776;</span>
                </button>
                <nav className={`nav-links ${isOpen ? 'open' : ''}`} id="primary-navigation">
                    <GalaxyButton as={Link} to="/">Home</GalaxyButton>
                    <GalaxyButton as={Link} to="/about">About</GalaxyButton>
                    <GalaxyButton as="a" href="http://www.cleandata.me/blog">Blog</GalaxyButton>
                    <GalaxyButton as="a" href="http://www.cleandata.me/contact">Contact</GalaxyButton>
                    <GalaxyButton as="a" href="http://www.cleandata.me/pricing">Pricing</GalaxyButton>
                    
                    <div className="dropdown-container" ref={dropdownRef}>
                        <GalaxyButton
                            onClick={toggleDropdown}
                            className="dropdown-toggle"
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen}
                        >
                            Free Tools {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
                        </GalaxyButton>
                        <ul className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
                            <li>
                                <button onClick={() => handleNavigation('/password-check')}>
                                    <FaLock />
                                    <span>Password Checker</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => handleNavigation('/file-scan')}>
                                    <FaShieldVirus />
                                    <span>Virus Scanner</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => handleNavigation('/location')}>
                                    <FaSearchLocation />
                                    <span>Data Broker Check</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => handleNavigation('/data-leak')}>
                                    <FaEnvelope />
                                    <span>Check Your Email</span>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => handleNavigation('/scamai')}>
                                    <FaRobot />
                                    <span>Scam AI Detector</span>
                                </button>
                            </li>
                        </ul>
                    </div>

                    {user ? (
                        <GalaxyButton onClick={handleLogout} className="logout-btn">
                            <span>Logout</span>
                            <FaSignOutAlt />
                        </GalaxyButton>
                    ) : (
                        <GalaxyButton as={Link} to="/login" className="login-btn">
                            Login
                        </GalaxyButton>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
