import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ScanningPage.css';
import peopleSearchSites from './peopleSearchSites';
import { fetchMatchProbability } from '../fetchMatchProbability';
import generateScreenshotUrls from '../utils/generateScreenshotUrls';
import {
    FaExclamationTriangle,
    FaSearch,
    FaShieldAlt,
    FaDatabase,
    FaInfoCircle,
    FaCheckCircle,
    FaBug,
    FaSpinner,
} from 'react-icons/fa';
import { siteCategories } from './siteCategories';  // Import the categories

const auth = '72382-cd';
const thumbioKey = '72571-1234';

// Utility function for delays
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to determine threat level based on site or match type
const getThreatLevel = (threat) => {
    const highRiskKeywords = ['financial', 'credit', 'ssn', 'criminal', 'address', 'phone'];
    const mediumRiskKeywords = ['social', 'employment', 'education', 'relatives'];

    const threatLower = threat.toLowerCase();

    if (highRiskKeywords.some((keyword) => threatLower.includes(keyword))) {
        return 'HIGH';
    }
    if (mediumRiskKeywords.some((keyword) => threatLower.includes(keyword))) {
        return 'MEDIUM';
    }
    return 'LOW';
};

// Constants
const SCAN_DURATION = 24000; // 24 seconds
const UPDATE_INTERVAL = 50; // 50ms for smooth updates
const SITE_DISPLAY_TIME = 100; // Base time to show each site (100ms)
const PAUSE_CHANCE = 0.15; // 15% chance to pause on a site
const PAUSE_DURATION = [300, 800]; // Random pause between 300-800ms
const EXPECTED_THREATS = 12; // Number of threats to generate during scan
const SCREENSHOTS_TO_SHOW = 6; // Number of screenshots to show during scan
const SCREENSHOT_DURATION = 4000; // How long to show each screenshot (4 seconds)

// Helper function to calculate expected matches based on progress
const calculateMatchesForProgress = (progress, totalSites, probability) => {
    const maxMatches = Math.ceil(totalSites * probability);
    const matchesAtThisPoint = Math.ceil((progress / 100) * maxMatches);
    return matchesAtThisPoint;
};

// Scan stages
const scanStages = [
    {
        stage: 'initializing',
        messages: [
            'Initializing secure scan...',
            'Setting up secure connection...',
            'Preparing scan parameters...',
        ],
    },
    {
        stage: 'searching',
        messages: [
            'Scanning data brokers...',
            'Checking social media platforms...',
            'Analyzing public records...',
            'Searching people finder sites...',
            'Scanning background check services...',
            'Checking address databases...',
        ],
    },
    {
        stage: 'analyzing',
        messages: [
            'Analyzing potential matches...',
            'Verifying data sources...',
            'Processing results...',
            'Compiling findings...',
        ],
    },
];

// Helper function to generate threat message
const generateThreatMessage = (siteName) => {
    const messages = [
        `Potential personal information detected on ${siteName}`,
        `Possible data exposure identified on ${siteName}`,
        `Investigating potential profile match on ${siteName}`,
        `Analyzing possible records on ${siteName}`,
        `Scanning for potential data breach on ${siteName}`,
        `Evaluating possible information leak on ${siteName}`,
    ];
    return messages[Math.floor(Math.random() * messages.length)];
};

const getThumbioUrl = (url, retry = false) => {
    const encodedUrl = encodeURIComponent(url);
    return `https://image.thum.io/get/auth/${thumbioKey}/width/800/crop/600/noanimate${retry ? '/wait/2' : ''}/png/?url=${encodedUrl}`;
};

const prefetchImage = async (url) => {
    try {
        const prefetchUrl = `https://image.thum.io/get/auth/${auth}/prefetch/${url}`;
        const response = await fetch(prefetchUrl);
        const text = await response.text();
        return text.includes("Image is cached");
    } catch (error) {
        console.error('Prefetch failed:', error);
        return false;
    }
};

const generateScreenshotUrl = (url) => {
    try {
        const cleanUrl = url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
        return `https://image.thum.io/get/auth/${auth}/width/800/crop/600/${cleanUrl}`;
    } catch (error) {
        console.error('URL generation error:', error);
        return null;
    }
};

const formatThreatMessage = (siteName, category) => {
    const exposureTypes = {
        'Background Check': {
            icon: '🔍',
            details: 'Possible background information available',
        },
        'Public Records': {
            icon: '📄',
            details: 'Potential public record information identified',
        },
        'People Search': {
            icon: '👤',
            details: 'Possible personal information available',
        },
        'Social Media': {
            icon: '📱',
            details: 'Social media presence detected',
        },
        'Contact Info': {
            icon: '📞',
            details: 'Contact details may be exposed',
        },
        'Address History': {
            icon: '🏠',
            details: 'Possible address history found',
        },
    };

    const exposureType = exposureTypes[category] || exposureTypes['People Search'];

    return {
        icon: exposureType.icon,
        category: category,
        details: exposureType.details,
    };
};

const generateScreenshotSites = (firstName, lastName) => {
    return [
        { url: `https://dataveria.com/profile/search?fname=${firstName}&lname=${lastName}`, siteName: 'Dataveria' },
        { url: `https://411.info/people?fn=${firstName}&ln=${lastName}`, siteName: '411.info' },
        { url: `https://www.anywho.com/people/${firstName}%20+${lastName}/`, siteName: 'AnyWho' },
        { url: `https://www.addresses.com/people/${firstName}+${lastName}/`, siteName: 'Addresses.com' },
        { url: `https://arrestfacts.com/ng/search?fname=${firstName}&lname=${lastName}&state=&city=`, siteName: 'ArrestFacts' },
        { url: `https://www.peekyou.com/${firstName}_${lastName}`, siteName: 'PeekYou' }
    ];
};

const MAX_SCREENSHOTS = 3; // Maximum number of screenshots to show

// Add this near the top of your component, after the imports
const getTotalUniqueSites = () => {
    // Get all sites from all categories
    const allSites = siteCategories.flatMap(category => category.sites);
    // Remove duplicates using Set
    const uniqueSites = new Set(allSites);
    // Return the count of unique sites
    return uniqueSites.size;
};

// Add this helper function to get all unique sites from siteCategories
const getAllUniqueSites = () => {
    const allSites = siteCategories.flatMap(category => category.sites);
    return [...new Set(allSites)]; // Remove duplicates and convert back to array
};

// Add this helper function at the top level (around line 50)
const generateDisplaySites = (shuffleArray) => {
    const displayCount = Math.floor(Math.random() * 21) + 60;
    const allSites = siteCategories.flatMap(category => category.sites);
    return shuffleArray([...allSites]).slice(0, displayCount);
};

const ScanningPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { firstName, lastName, city, country } = location.state || {};

    // State Variables
    const [scannedCount, setScannedCount] = useState(0);
    const [matchCount, setMatchCount] = useState(0);
    const [matchProbability, setMatchProbability] = useState(null);
    const [shuffledSites, setShuffledSites] = useState([]);
    const [screenshotUrls, setScreenshotUrls] = useState([]);
    const [currentScreenshot, setCurrentScreenshot] = useState(null);
    const [currentThreat, setCurrentThreat] = useState(null);
    const [progress, setProgress] = useState(0);
    const [scanStage, setScanStage] = useState('initializing');
    const [scanMessage, setScanMessage] = useState('Initializing scan...');
    const [error, setError] = useState('');
    const [foundThreats, setFoundThreats] = useState([]);
    const [scanSpeed, setScanSpeed] = useState(0);
    const [estimatedTimeLeft, setEstimatedTimeLeft] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [currentSite, setCurrentSite] = useState(null);
    const [loadingStates, setLoadingStates] = useState({});
    const [currentScreenshotUrl, setCurrentScreenshotUrl] = useState(null);
    const [isScanning, setIsScanning] = useState(true);
    const [currentStageIndex, setCurrentStageIndex] = useState(0);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [totalSites, setTotalSites] = useState(0);
    const headerRef = useRef(null);

    // Refs
    const matchCountRef = useRef(0);
    const foundThreatsRef = useRef([]);
    const siteIndexRef = useRef(0);

    // Update refs whenever state changes
    useEffect(() => {
        matchCountRef.current = matchCount;
    }, [matchCount]);

    // Effect to handle stage progression
    useEffect(() => {
        if (!isScanning) return;

        const messageInterval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => {
                const currentStage = scanStages[currentStageIndex];
                if (prevIndex >= currentStage.messages.length - 1) {
                    // Move to next stage
                    if (currentStageIndex < scanStages.length - 1) {
                        setCurrentStageIndex((prev) => prev + 1);
                        return 0;
                    }
                }
                return (prevIndex + 1) % currentStage.messages.length;
            });
        }, 2000); // Change message every 2 seconds

        return () => clearInterval(messageInterval);
    }, [currentStageIndex, isScanning]);

    // Function to shuffle array
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Fetch and set the screenshot URLs and shuffled sites
    useEffect(() => {
        const initializeScan = async () => {
            try {
                if (!firstName || !lastName || !city || !country) {
                    setError('Missing required information. Please go back and provide your details.');
                    return;
                }

                // Generate URLs first
                const urls = generateScreenshotSites(firstName, lastName);

                // Generate screenshot URLs with thum.io
                const screenshotUrls = urls.map((site) => ({
                    url: generateScreenshotUrl(site.url),
                    siteName: site.siteName,
                    originalUrl: site.url,
                }));
                setScreenshotUrls(screenshotUrls);

                // Initialize sites for scanning
                const sites = urls.map((site) => ({
                    name: site.siteName,
                    url: site.url,
                }));
                setShuffledSites(shuffleArray(sites));

                // Fetch match probability
                const probability = await fetchMatchProbability(firstName, lastName, city, country);
                setMatchProbability(probability);
            } catch (error) {
                console.error('Error initializing scan:', error);
                setError('Failed to initialize scan. Please try again.');
            }
        };

        initializeScan();
    }, [firstName, lastName, city, country]);

    // Log matchProbability whenever it updates
    useEffect(() => {
        if (matchProbability !== null) {
            const estimatedMatches = Math.ceil(matchProbability * shuffledSites.length);
            const threatPercentage = (estimatedMatches / shuffledSites.length) * 100;
            console.log(`Estimated Matches: ${estimatedMatches}`);
            console.log(`Threat Percentage: ${threatPercentage}%`);
        }
    }, [matchProbability, shuffledSites.length]);

    // Helper function to get random sites from categories
    const getRandomSitesFromCategories = () => {
        let allThreats = [];
        siteCategories.forEach(category => {
            // Get 2-3 random sites from each category
            const numSites = Math.floor(Math.random() * 2) + 2; // 2-3 sites per category
            const shuffledCategorySites = shuffleArray([...category.sites]);
            const selectedSites = shuffledCategorySites.slice(0, numSites);
            
            selectedSites.forEach(site => {
                allThreats.push({
                    siteName: site,
                    category: category.name,
                    details: getSiteSpecificDetails(site)
                });
            });
        });
        return shuffleArray(allThreats);
    };

    // Update the scanning effect
    useEffect(() => {
        if (!matchProbability || !shuffledSites.length) return;

        const scanSites = async () => {
            setIsScanning(true);
            let currentIndex = 0;
            const startTime = Date.now();
            let elapsedTime = 0;
            let threatsFound = 0;

            // Calculate total expected threats based on matchProbability
            const totalSites = 208; // Same as in fetchMatchProbability
            const expectedMatches = Math.ceil(matchProbability * totalSites);
            
            const screenshotSites = generateScreenshotSites(firstName, lastName);
            let screenshotIndex = 0;

            while (elapsedTime < SCAN_DURATION && isScanning) {
                elapsedTime = Date.now() - startTime;
                const progress = Math.min((elapsedTime / SCAN_DURATION) * 100, 100);
                setProgress(progress);

                // Show screenshots at intervals
                if (screenshotIndex < screenshotSites.length) {
                    const showAt = ((screenshotIndex + 1) / (screenshotSites.length + 1)) * SCAN_DURATION;
                    if (elapsedTime >= showAt) {
                        setImageLoaded(false);
                        setCurrentScreenshot(screenshotSites[screenshotIndex]);
                        await delay(SCREENSHOT_DURATION);
                        setCurrentScreenshot(null);
                        screenshotIndex++;
                    }
                }

                // Generate threats based on progress
                if (threatsFound < expectedMatches && Math.random() < 0.1) {
                    const category = siteCategories[Math.floor(Math.random() * siteCategories.length)].name;
                    const currentSite = shuffledSites[Math.floor(Math.random() * shuffledSites.length)];
                    const newThreat = {
                        siteName: currentSite.name,
                        category: category,
                        details: `Personal information exposed on ${currentSite.name}`
                    };
                    setFoundThreats(prev => [...prev, newThreat]);
                    setCurrentThreat(generateThreatMessage(currentSite.name));
                    threatsFound++;
                }

                // Check if scan is complete
                if (progress >= 100) {
                    setIsScanning(false);
                    await delay(1000);

                    // Ensure we have the minimum number of threats
                    const finalThreats = foundThreats.length < 12 ? 
                        [...foundThreats, ...generateAdditionalThreats(12 - foundThreats.length)] : 
                        foundThreats;

                    // Calculate final values
                    const threatPercentage = ((finalThreats.length / totalSites) * 100).toFixed(2);
                    const riskScore = Math.ceil(matchProbability * 5);

                    // Navigate to results with complete data
                    navigate('/results', {
                        replace: true,
                        state: {
                            threats: finalThreats,
                            firstName,
                            lastName,
                            totalMatches: finalThreats.length,
                            riskScore,
                            threatPercentage: `${threatPercentage}%`
                        }
                    });
                    return; // Exit the function after navigation
                }

                await delay(UPDATE_INTERVAL);
            }
        };

        scanSites();
    }, [matchProbability, shuffledSites, navigate, firstName, lastName]);

    // Add this helper function to generate additional threats if needed
    const generateAdditionalThreats = (count) => {
        const additionalThreats = [];
        for (let i = 0; i < count; i++) {
            const category = siteCategories[Math.floor(Math.random() * siteCategories.length)].name;
            const site = shuffledSites[Math.floor(Math.random() * shuffledSites.length)];
            additionalThreats.push({
                siteName: site.name,
                category: category,
                details: `Personal information exposed on ${site.name}`
            });
        }
        return additionalThreats;
    };

    // Add image load handler
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    // Verify that required data is available
    useEffect(() => {
        if (!location.state) {
            console.error('No location state found');
            setError('Missing required information. Please go back and try again.');
            return;
        }

        if (!firstName || !lastName || !city || !country) {
            console.error('Missing required fields:', { firstName, lastName, city, country });
            setError('Missing required information. Please go back and provide your details.');
            return;
        }
    }, [location.state, firstName, lastName, city, country]);

    // Update site-specific details function
    const getSiteSpecificDetails = (siteName) => {
        // Find the category for this site
        const category = siteCategories.find(cat => 
            cat.sites.includes(siteName)
        );

        const detailsByCategory = {
            'Background Check': 'Exposes background check information and personal history',
            'Public Records': 'Displays public records and legal information',
            'People Search': 'Reveals comprehensive personal information and connections',
            'Social Media': 'Aggregates social media profiles and online presence',
            'Contact Info': 'Exposes contact details and communication records',
            'Address History': 'Shows current and previous address information'
        };

        return detailsByCategory[category?.name] || 'Personal information exposed on this site';
    };

    // Add this useEffect to set the total sites count when component mounts
    useEffect(() => {
        setTotalSites(getTotalUniqueSites());
    }, []);

    // Add this new useEffect for scrolling
    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, []); // Empty dependency array means this runs once when component mounts

    return (
        <div className="scanning-page">
            {error ? (
                <div className="error-message">
                    <p>{error}</p>
                    <button onClick={() => navigate(-1)}>Go Back</button>
                </div>
            ) : (
                <>
                    <div className="scanning-header" ref={headerRef}>
                        <h1>Scanning Data Brokers for:</h1>
                        <h2>
                            {firstName} {lastName}
                        </h2>
                        <p>Searching {totalSites} websites for your personal information</p>
                    </div>

                    <div className="scan-status-display">
                        <div className="current-stage">
                            <FaSearch className="stage-icon" />
                            <h3 className="stage-message">{scanStages[currentStageIndex].messages[currentMessageIndex]}</h3>
                        </div>
                        <div className="current-action">
                            {currentSite && (
                                <div className="current-site">
                                    Currently scanning: <span className="site-name">{currentSite.name}</span>
                                </div>
                            )}
                            {currentThreat && <div className="current-threat">{currentThreat}</div>}
                        </div>
                    </div>

                    <div className="threats-section">
                        <h3>
                            <FaInfoCircle className="info-icon" />
                            Potential Exposures
                            <span className="exposure-count">{foundThreats.length} identified</span>
                        </h3>
                        <div className="threats-list">
                            {foundThreats.map((threat, index) => {
                                const { siteName, category } = threat;
                                const { icon, details } = formatThreatMessage(siteName, category);
                                return (
                                    <div key={index} className="exposure-item">
                                        <div className="exposure-top">
                                            <div className="exposure-icon">{icon}</div>
                                            <div className="exposure-content">
                                                <div className="exposure-header">
                                                    <span className="site-name">{siteName}</span>
                                                    <span className="exposure-category">{category}</span>
                                                </div>
                                                <div className="exposure-details">{details}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="screenshot-section">
                        <h3>
                            <FaSearch />
                            Live Scan Results
                        </h3>
                        <div className="screenshot-container">
                            {currentScreenshot ? (
                                <>
                                    {!imageLoaded && (
                                        <div className="screenshot-placeholder">
                                            <div className="cyber-spinner"></div>
                                            <p>Scanning {currentScreenshot.siteName}...</p>
                                        </div>
                                    )}
                                    <img
                                        src={generateScreenshotUrl(currentScreenshot.url)}
                                        alt={`Screenshot of ${currentScreenshot.siteName}`}
                                        className={`screenshot ${imageLoaded ? 'loaded' : ''}`}
                                        onLoad={() => setImageLoaded(true)}
                                        onError={(e) => {
                                            console.error('Screenshot failed to load:', currentScreenshot.url);
                                            e.target.src = generateScreenshotUrl(currentScreenshot.url);
                                        }}
                                        style={{ display: imageLoaded ? 'block' : 'none' }}
                                    />
                                </>
                            ) : (
                                <div className="screenshot-placeholder">
                                    <FaSearch className="scan-icon" />
                                    <p>Scanning sites...</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="info-box-bottom">
                        <FaInfoCircle className="info-icon-bottom" />
                        <p>
                            This is a quick free scan. With limited information, we cannot fully locate or remove all your
                            personal data from the internet. To take full control of your data, sign up for a{' '}
                            <strong>free trial</strong> and get your data removed.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default ScanningPage;
