import React from 'react';
import { FaUser, FaCalendar, FaTag } from 'react-icons/fa';
import './UserInfo.css';

const UserInfo = ({ user }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    return (
        <div className="user-info">
            <h3 className="user-info-header">Account Info</h3>
            <div className="user-info-content">
                <div className="info-item">
                    <div className="info-label">
                        <FaUser /> Email
                    </div>
                    <div className="info-value user-email">
                        {user?.email || 'Not available'}
                    </div>
                </div>

                <div className="info-item">
                    <div className="info-label">
                        <FaCalendar /> Member since
                    </div>
                    <div className="info-value">
                        {user?.memberSince ? formatDate(user.memberSince) : 'Not available'}
                    </div>
                </div>

                <div className="info-item">
                    <div className="info-label">
                        <FaTag /> Status
                    </div>
                    <div className="status-badge">
                        {user?.subscriptionStatus || 'Not available'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;
