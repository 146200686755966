import React from 'react';
import { FaSearch, FaExclamationTriangle, FaDatabase, FaClock } from 'react-icons/fa';
import './DashboardStats.css';

const DashboardStats = ({ stats = {} }) => {
    const { 
        sitesScanned = 0,
        lastScanTime = null,
        nextScanTime = null,
        isScanning = false,
        currentSite = 'Not scanning',
        progress = 0,
        profilesFound = 0,
        totalMatches = 0
    } = stats;

    const formatTimeAgo = (timestamp) => {
        if (!timestamp) return 'Starting Soon...';
        try {
            const now = new Date();
            const scanTime = new Date(timestamp);
            if (isNaN(scanTime.getTime())) return 'Starting Soon..';
            const diff = now - scanTime;
            const minutes = Math.floor(diff / (1000 * 60));
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            if (days > 0) return `${days}d ago`;
            if (hours > 0) return `${hours}h ago`;
            if (minutes > 0) return `${minutes}m ago`;
            return 'Just now';
        } catch (error) {
            return 'Starting Soon...';
        }
    };

    return (
        <div className="dashboard-stats-container">
            {isScanning ? (
                <>
                    <div className="stats-grid">
                        <div className="stat-card scanning">
                            <div className="stat-icon">
                                <FaSearch className="icon pulse" />
                            </div>
                            <div className="stat-content">
                                <h3>Sites Scanned</h3>
                                <p className="stat-value">{sitesScanned}</p>
                                <div className="stat-subtitle">Currently scanning: {currentSite}</div>
                            </div>
                        </div>

                        <div className="stat-card scanning">
                            <div className="stat-icon">
                                <FaExclamationTriangle className="icon warning" />
                            </div>
                            <div className="stat-content">
                                <h3>Potential Threats</h3>
                                <p className="stat-value">{profilesFound}</p>
                                <div className="stat-subtitle">Matches found so far</div>
                            </div>
                        </div>

                        <div className="stat-card scanning">
                            <div className="stat-icon">
                                <FaDatabase className="icon" />
                            </div>
                            <div className="stat-content">
                                <h3>Total Matches</h3>
                                <p className="stat-value">{totalMatches}</p>
                                <div className="stat-subtitle">Across all sites</div>
                            </div>
                        </div>
                    </div>

                    <div className="progress-container">
                        <div className="progress-info">
                            <span>Scan Progress</span>
                            <span className="progress-percentage">{Math.round(progress)}%</span>
                        </div>
                        <div className="progress-bar-container">
                            <div 
                                className="progress-bar" 
                                style={{ width: `${progress}%` }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="stats-grid">
                    <div className="stat-card">
                        <div className="stat-icon">
                            <FaSearch className="icon" />
                        </div>
                        <div className="stat-content">
                            <h3>Last Scan</h3>
                            <p className="stat-value">{formatTimeAgo(lastScanTime)}</p>
                            <div className="stat-subtitle">{sitesScanned} sites checked</div>
                        </div>
                    </div>

                    <div className="stat-card">
                        <div className="stat-icon">
                            <FaExclamationTriangle className="icon warning" />
                        </div>
                        <div className="stat-content">
                            <h3>Threats Found</h3>
                            <p className="stat-value">{profilesFound}</p>
                            <div className="stat-subtitle">Potential exposures</div>
                        </div>
                    </div>

                    <div className="stat-card">
                        <div className="stat-icon">
                            <FaDatabase className="icon" />
                        </div>
                        <div className="stat-content">
                            <h3>Total Matches</h3>
                            <p className="stat-value">{totalMatches}</p>
                            <div className="stat-subtitle">Across all sites</div>
                        </div>
                    </div>
                </div>
            )}

            {!isScanning && nextScanTime && (
                <div className="next-scan-info">
                    <FaClock className="icon" />
                    <span>Next scan scheduled for: {new Date(nextScanTime).toLocaleString()}</span>
                </div>
            )}
        </div>
    );
};

export default DashboardStats;
